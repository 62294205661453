@import "./config.scss";
// @font-face {
//   font-family: 'Open Dyslexic';
//   src: url(./utils/fonts/OpenDyslexic-Regular.otf);
// }
// @font-face {
//   font-family: 'PT Sans';
//   src: url(./utils/fonts/PTSans-Regular.ttf);
// }
// @font-face {
//   font-family: 'PT Sans';
//   font-style: italic;
//   src: url(./utils/fonts/PTSans-Italic.ttf);
// }
// @font-face {
//   font-family: 'PT Sans';
//   font-weight: bold;
//   src: url(./utils/fonts/PTSans-Bold.ttf);
// }
// @font-face {
//   font-family: 'PT Sans';
//   font-style: italic;
//   font-weight: bold;
//   src: url(./utils/fonts/PTSans-BoldItalic.ttf);
// }
// @font-face {
//   font-family: 'Courier Prime';
//   src: url(./utils/fonts/CourierPrime-Regular.ttf);
// }
// @font-face {
//   font-family: 'Courier Prime';
//   font-style: italic;
//   src: url(./utils/fonts/CourierPrime-Italic.ttf);
// }
// @font-face {
//   font-family: 'Courier Prime';
//   font-weight: bold;
//   src: url(./utils/fonts/CourierPrime-Bold.ttf);
// }
// @font-face {
//   font-family: 'Courier Prime';
//   font-style: italic;
//   font-weight: bold;
//   src: url(./utils/fonts/CourierPrime-BoldItalic.ttf);
// }

.area {
  background-color: $default-bg-color;
  border-radius: 8px;
  padding: 1em 2em;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
  margin: 1.3rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.blur {
  opacity: 0.5;
}

.specific__link {
  color: #e63946 !important;
}

.save-indica {
  position: absolute;
  right: 36px;
  top: 4px;
}

.save-indicator {
  opacity: 0;
  width: 200px;
  color: #ddd;
}

.saving {
  animation: blink 0.5s;
  animation-iteration-count: infinite;
}

.saved {
  animation: blind 2s;
}





/*

  Main Top Nav Bar

*/

.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  padding: 0 1.5em;
  height: 60px;
  background-color: $btn-bg-color;
  z-index: 30;

  &.auth {
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
  }
}
.main {
  padding-top: 60px;
}

.at-radio-nav {

  .ant-radio-button-wrapper, .ant-radio-button-wrapper-checked, .ant-radio-button-wrapper:hover, .ant-radio-button-wrapper-checked:hover, .ant-radio-button-wrapper:active, .ant-radio-button-wrapper-checked:active {
    background-color: transparent;
    border: none;
    border-radius: 0;
    color: white;
  }

  .ant-radio-button-wrapper-checked:focus-within{
    box-shadow: none!important;
  }

  .ant-radio-button-wrapper {
    opacity: 0.25;
    transition: .2s;
    font-size: 1rem;
  }

  .ant-radio-button-wrapper-checked{
    opacity: 0.8;
  }

  .ant-radio-button-wrapper:hover {
    opacity: 1!important;
  }
  .ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
  }
}

.nav-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
}


/* 
  Book Details in Navigation
*/

.book-details-nav {
  display: flex;
  align-items: center;

  .book-details-nav-icon {
    display: flex;
    align-items: center;
    padding-right: 1rem!important;
    margin-right: 0.5em;
  }
  .book-details-nav-title-col{
    flex: none;
  } 

  .book-details-nav-title {
    font-size: 1.125rem;
    color: white;
    font-weight: 400;
    overflow: hidden; 
    white-space: nowrap; 
    text-overflow: ellipsis;
  }

  .book-details-nav-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    font-size: 14px;
    color: white;
    border-radius: 5px;
    border: 1px solid white;
    background: none!important;
    position: fixed;
    top: 12px;  
  }
}

/* Profile Nav bar*/

.home-nav-icon{
  display: flex;
  align-items: center;
}

.margin-bottom {
  padding-bottom: 1rem;
}

.text-center {
  text-align: center;
}

.center-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

.form-field {
  padding: 1rem 0rem;

  .MuiInputBase-input {
    font-size: 1.4rem;
  }

  .MuiFormHelperText-root {
    font-size: 1rem;
  }
}

.potted {
  padding: 2rem;
}

.att-stats {
  .ant-comment-content-detail {
    font-size: 1.5rem;
  }

  .ant-comment-content-author-name {
    text-transform: capitalize;
  }
}

.container-profile {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; 
  font-size: 1.2rem;
}

.att-toc {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    font-style: oblique;
    margin: 0;
  }
}

.field {
  flex: 1;
  margin: 1rem 0rem 1.2rem 0rem;

  .MuiTypography-subtitle2 {
    margin-bottom: 0.4rem;
  }
}

.previewer {
  display: flex;
  justify-content: center;

  .textbox {
    position: "relative";
    height: 100%;
  }
}

.previewer-wrapper.full {
  min-width: 440px;
  overflow-x: hidden !important;
}

.profile-text-h {
  font-size: large;
  font-weight: 600;
}

.profile-nav-dropdown {
  margin-top: 10px;
  background: $default-bg-color 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  padding: 15px;
  box-shadow: 4px 11px 24px $secondary-bg-black;

  .profile-text{
    font-size: large;
    font-weight: 600;
  }

  .profile-dropdown-user {
    padding-left: 10px;
  }
  .profile-dropdown-email{
    font-size: 0.8rem;
    color: $secondary-bg-grey; 
  }

  .profile-dropdown-divider {
    width: 100%;
    margin-top: 55px;
    position: absolute;
    left: 0;
    right: 0;
  }

  .profile-dropdown-link-container {
    padding-top: 18px;
  }

  .profile-dropdown-button {
    padding-left: 0px;
  }

  .logout-btn {
    padding-left: 0px;
    font-weight: 600;
  }

  .profile-dropdown-text{
    color: $secondary-bg-black;
  }
}

.previewer-print {
  margin-bottom: 2rem !important;
  display: flex;
  background-color: $default-bg-color;
  margin-bottom: 2em;
  position: relative;
  box-shadow: 0 0.5em 2em 0.2em rgba(0, 0, 0, 0.33);
  transition: all 0.1s linear, line-height 0s linear;
  transform-origin: bottom center;
  z-index: 10;
  padding: 0 16px;
  align-items: center;
}

.theme-carousel {

  //  * {
  //   all: unset
  //  }
  .slick-dots {
    bottom: -25px;

    .slick-active {
      button {
        background-color: $slick-bg-btn-color !important;
      }
    }

    button {
      background-color: $dark-gray-bg-color !important;
    }
  }
}

// .theme-card:focus  {
// }

.a-select {
  .ant-select-selector {
    display: flex;
    align-items: center;
    height: 40px !important;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
    border-radius: 8px !important;
    border: none !important;
    font-size: 16px;
    padding: 0.25em 1em;
  }
}

.a-radio {
  display: flex;
  align-items: baseline;
  justify-content: space-evenly;
  padding: 1em 0;
  margin-top: -8px;

  .ant-radio-button-wrapper {
    padding: 0.5em !important;
    border-radius: 0;
    border: none !important;
    border-color: $default-bg-color;
  }

  .ant-radio-button-wrapper::before {
    visibility: hidden;
  }
}

.edit-book-cover {
  overflow-y: auto;
  height: 82vh;
  padding: 1rem 2rem;
  min-width: 700px;
}

.book-details {
  padding-right: 20px;
}

.theme-config {
  width: 100%;

  .ant-checkbox-wrapper,
  .ant-radio-wrapper {
    color: $darker-gray-color1;
  }
}

.a-radio-btns {
  width: 100%;
  max-width: 720px;

  .ant-radio-group {
    display: flex;

    .ant-radio-button-wrapper {
      width: 23%;
      margin: 1%;
      color: $dark-gray-bg-color;
      border-radius: 5px !important;
      border-left-width: 1px;
    }

    .ant-radio-button-wrapper-checked {
      color: white;
    }

    ::before {
      display: none !important;
    }
  }
}

.hf-layout-btns {
  overflow-x: auto;
  width: 100%;

  .ant-radio-group {
    display: flex;
  }

  .ant-radio-wrapper {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;

    .hf-layout {
      background-color: $default-gray-bg-color;
      border-radius: 5px;
      display: flex;
      font-weight: 300;
      font-size: 12px;
      color: black;
      margin-bottom: 1em;

      .left,
      .right {
        padding: 0.75em;
        width: 100px;

        .hf-header {
          display: flex;
          justify-content: space-between;
          height: 30px;

          // div {
          //   flex: 1;
          // }
        }

        .body {
          display: flex;
          flex-direction: column;
          height: 60px;

          div:first-child {
            width: 50px;
            align-self: flex-end;
          }

          div:last-child {
            width: 50px;
            align-self: flex-start;
          }

          div {
            height: 6px;
            margin-bottom: 5px;
            border-radius: 2px;
            background-color: $lighter-gray-color1;
          }
        }

        .hf-footer {
          height: 20px;
          text-align: center;
        }
      }

      .left {
        border-right: 1px solid $lighter-gray-color1;
      }
    }
  }

  .ant-radio-wrapper-checked {
    .hf-layout {
      background-color: $default-area-bg-color;

      .left,
      .right {
        .body {
          div {
            background-color: $lighter-slick-bg-color;
          }
        }
      }

      .left {
        border-right: 1px solid $lighter-slick-bg-color;
      }
    }
  }
}

.att-segment-a {
  width: 100%;

  .ant-segmented-item,
  .ant-segmented-item-label {
    height: 30px;
    line-height: 30px;

  }

  .ant-segmented-item-selected,
  .ant-segmented-thumb-motion-appear {
    background-color: black;
    color: white;
  }
}

.book-stat {
  border-left: 1px solid $white-bg-color1;
  padding-left: 20px;
}


.ant-tooltiop {
  z-index: 1200;
}

.a-modal {
  .ant-modal-content {
    background-color: $default-bg-color;
  }

  .ant-modal-footer {
    padding: 2em;
    display: flex;
    justify-content: center;

    button {
      width: 200px;
    }
  }
}

.theme-wrapper {
  max-height: calc(100vh - 150px) !important;
}

.thm-img img {
  max-width: 400px;
}

.thm-img-delete-button {
  margin-top: 10px;
}

.att-title-card-avatar {
  margin-top: 0.25em;
  cursor: pointer;
  position: relative;
  background-color: whitesmoke;

  &:hover {
    .att-hover {
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.8);
    }
  }

  .ant-avatar-string {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transform: none !important;
  }

  .att-title-card-img {
    width: 100%
  }

  .att-hover {
    opacity: 0;
    width: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0);
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
}


.sidebar-collapse {
  padding-top: 0px;
  padding-bottom: 0rem;
}

.sidebar-collapse-custom-panel {
  padding-top: 0px;
  padding-bottom: 0px !important;
}

.sidebar-collapse {
  .ant-collapse-header {
    font-weight: 700;
  }

  .ant-collapse-content>.ant-collapse-content-box {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
}

/*
.ant-input-extended {
  margin-bottom: 25px;
  margin-top: 40px;

  input[type="text"], input[type="email"], input[type="password"] {
    border-radius: 0 !important;
    border: none !important;
    border-bottom: 2px solid $light-gray-color !important;
    background-color: transparent !important;
    padding-left: 0;

    &:focus {
      outline: none !important;
      box-shadow: none;
    }
  }
  .ant-form-item-label {
    //display: none;
  }
}
*/

/*
.ant-select-extended {
  margin-bottom: 25px;
  margin-top: 40px;

  input[type="search"], .ant-select-selector {
    border-top: none !important;
    border-left: none !important;
    border-radius: 0 !important;
    border-right: 0 !important;
    padding-left: 0 !important;

    &:focus {
      outline: none !important;
      box-shadow: none !important;
      border: none !important;
    }
  }

  .ant-select-arrow {
    display: none;
  }

  .ant-select-multiple .ant-select-selection-placeholder {
    left: 0px;
  }
}
*/

.loading-animation-container {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .loading-container {
    vertical-align: middle;
    text-align: center;
    margin: 0 auto;
    max-width: 600px;

    >div {
      display: block;
      margin: 0 auto;
    }
  }

  .loading-animation {
    margin-top: 10%;
    width: 325px;
  }

  .loading-animation-text {
    font-weight: $font-bold;
  }
}

.default__flex--wrapper {
  display: flex;
}

@keyframes blink {
  0% {
    opacity: 0.2;
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}

@keyframes blind {
  0% {
    opacity: 1;
  }

  98% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* HEADERS */

.section-heading {
  font-size: 2rem;
  font-weight: 600;
  color: $subheading-text-color;
  text-align: center;
}

.after-section {
  padding: 1rem 0rem;
}


.confirm-body {

  .body-text {
    margin: 5px;
  }

  .button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 10px;

    Button {
      margin-left: 5px;
      margin-right: 5px;
      flex: 1
    }
  }

}

/* INPUT */

.toolbar-label {
  padding: 0rem 1rem;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.3);
}

.atticus-editor-layout {
  background-color: $primary-background;

  .ant-layout-sider-zero-width-trigger {
    background-color: black;
    color: white;
  }

  ._right,
  ._left,
  ._center {
    background-color: $primary-background;
  }
}

.book-load-wrapper {
  height: "100%";
  display: "flex";
  align-items: "center";
}

.maintenance-screen {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../images/WelcomeBackground.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: bottom;
  background-position-x: center;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .maintenance-card {
    width: 400px;
    text-align: center;

    h1 {
      font-size: 32px;
      font-weight: 600;
      padding: 1rem 0rem;
    }

    p {
      font-size: 18px;
      font-weight: 300;
    }
  }
}

.welcome-screen {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../images/WelcomeBackground.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: bottom;
  background-position-x: center;

  .welcome-card {
    min-width: 400px;
    text-align: center;
    margin-bottom: 10em;

    h1 {
      font-size: 32px;
      font-weight: 600;
      padding: 1rem 0rem;
    }

    p {
      font-size: 18px;
      font-weight: 300;
    }

    .horizontal-list {
      padding: 2rem 0rem;
    }
  }
}

.avatar__mask--container {
  background: $subheading-text-color 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 40px 40px;
  opacity: 0.4;
  height: 40px;
  width: 80px;
  position: absolute;
  top: 63px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.profile__image--change--container {
  cursor: pointer;
  width: 80px;
}

.padding__bottom--zero {
  padding-bottom: 0;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #E8E8E9;
  padding: 0px 4px;
}

// ::-webkit-scrollbar-thumb,
// .rc-virtual-list-scrollbar-thumb {
//   border-radius: 8px;
//   background-color: #3568BA;
// }

.scroller {
  &::-webkit-scrollbar{
    width: 8px;
    background-color: transparent;
  }

  &:hover::-webkit-scrollbar{
    background-color: #E8E8E9;
    border-radius: 4px;
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: #3568BA;
    border-radius: 4px;
  }
}

.fullWidth {
  width: 100%
}

.custom-themes-empty {
  background-color: #e9f3fb;
  flex-direction: column;
  border-radius: 12px;
}

.generic__gray--button {
  background-color: $common-theme-bg-color;
  font-weight: $font-semi-bold !important;
  display: flex;
  align-items: center;
  z-index: 200;

  span {
    font-size: $font-size;
    color: $darker-gray-color;
  }

  &:hover {
    opacity: 0.8;
  }

  &:active {
    background-color: $slick-bg-btn-color !important;

    span {
      font-size: $font-size;
      color: $default-bg-color;
    }
  }

  &:focus {
    background-color: $slick-bg-btn-color !important;

    span {
      font-size: $font-size;
      color: $default-bg-color;
    }

  }

}

.generic__black--button {
  background-color: $subheading-text-color !important;
  font-weight: $font-semi-bold !important;
  display: flex;
  align-items: center;
  z-index: 100;

  span {
    font-size: $font-size;
  }

  &:hover {
    opacity: 0.8;
  }
}

.previewer {
  min-width: 440px;
  display: flex;
  justify-content: center;
  //height: "calc(100vh - 100px)",
  padding: 1.5em 0 0 0; //0 2em;
  flex: 0;
}

.attcus-chapters-menu {
  height: calc(100vh - 60px);
  overflow-y: hidden;
  display: block;
  padding: 1rem;
  max-width: 300px;
  float: left;
  width: 40%;
  padding-top: 0;
}

.ant-drawer-header {
  background-color: #e8f2fd;
  border-radius: 0;
}

.ant-drawer-title {
  color: #fff;
}

.ant-drawer-body {
  background-color: #e8f2fd;
  color: #fff;
  height: calc(100vh - 55px);
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  color: black;
  font-size: 20px;
}

.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  color: black;
  font-size: 20px;
}

.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  color: black;
  font-size: 20px;
}

@media only screen and (min-width: 1000px) {
  .ant-checkbox-wrapper+.ant-checkbox-wrapper {
    margin-left: 0;
  }
}

@media only screen and (min-width: 1330px) {
  .ant-radio-group-outline {
    display: flex;
  }

  .a-radio-btns .ant-radio-group .ant-radio-button-wrapper {
    width: 22%;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
  }

  .atticus-menu {
    display: none;
  }
}

// new theme
.theme-layout-btns {
  overflow-x: auto;
  width: 100%;

  .ant-radio-group {
    display: flex;
  }

  .ant-radio {
    display: none;

    &+* {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .ant-radio-wrapper {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;

    .theme-layout {
      // background-color: $default-gray-bg-color;
      border-radius: 10px;
      display: flex;
      font-weight: 300;
      font-size: 12px;
      color: black;
      margin-bottom: 1em;

      .left,
      .right {
        padding: 0.75em;
        width: 100px;

        .theme-layout-header {
          display: flex;
          justify-content: space-between;
          height: 30px;
        }

        .body {
          display: flex;
          flex-direction: column;
          height: 60px;

          div:first-child {
            width: 50px;
            align-self: flex-end;
          }

          div:last-child {
            width: 50px;
            align-self: flex-start;
          }

          div {
            height: 6px;
            margin-bottom: 5px;
            border-radius: 2px;
            background-color: $lighter-gray-color1;
          }
        }

        .theme-layout {
          height: 20px;
          text-align: center;
        }
      }

      .left {
        border-right: 3px solid $lighter-gray-color1;
      }
    }
  }

  .ant-radio-wrapper-checked {
    .theme-layout {
      // background-color: $default-area-bg-color;
      border: 3px solid black;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    }
  }
}

.sync-pop-up {
  padding: 20px;
  margin: 10px;
  padding-left: 4.5em;
}

.writing-setting-container {
  overflow: hidden !important;
  display: flex !important;
  flex-direction: column;
  height: 645px;
  width: 329px;
  flex: 1;

  .content {
    flex: 1;
    background-color: $default-bg-color;
    border-radius: 5px;
    height: calc(101vh - 150px);
    padding: 0em 1.5em !important;
    box-shadow: $default-box-shadow;
    overflow-y: auto;

    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

}

/** custom css for align plugin with plate */

.plate-custom-align_right-block {
  text-align: right;
}

.plate-custom-align_left-block {
  text-align: left;
}

.plate-custom-align_center-block {
  text-align: center;
}

.plate-custom-align_justify-block {
  text-align: justify;
}

.plate-disabled {
  pointer-events: none;
  cursor: default !important;

  &>* {
    cursor: default !important;
  }

  .slate-ToolbarButton-active,
  .slate-ToolbarButton:hover {
    color: rgb(68, 68, 68) !important;
  }
}

/** custom css for verse plugin with plate */

.plate-custom-verse-block {
  font-size: 16px;
  padding: 12px 16px;
  background-color: rgb(247, 246, 243);
  border-radius: 3px;
  white-space: pre-wrap;
  font-family: SFMono-Regular, Consolas, Monaco, "Liberation Mono", Menlo, Courier, monospace;
  tab-size: 2;
  line-height: normal;
}

.plate-custom-hanging-indent-block {
  font-size: 16px;
  padding: 12px 16px;
  border-radius: 3px;
  white-space: pre-wrap;
  tab-size: 2;
  text-indent: -24px;
  padding-left: 24px;
  line-height: normal;

  .link-edit-btn {
    margin-left: 25px;
    padding-left: 10px !important;
    padding-right: 0px !important;
  }

  .slate-endnote {
    padding-right: 0em;
    padding-left: 1.9em;
  }
}

.fnr-input-section {
  input.ant-input {
    padding-left: 12px;
  }

  input.ant-input+span.ant-input-suffix {
    background-color: #f7f7f7;
    padding: 0;
    margin: 0px;
    padding-right: 22px;
    border-radius: 0 5px 5px 0;
  }

  .fnr-input {
    padding: 0;
    width: 100%;
    height: 40;
    background-color: #f7f7f7;
  }

  .warning-icon-yellow {
    color: #eed202;
  }
}

.fnr-warning-popover-content {
  color: white;

  a {
    color: #00be95;
  }
}

.btn-chapter-settings {
  height: 2.5em;
  width: 2.5em;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none !important;
  border: none;

  &:hover {
    box-shadow: none !important;
    border: none !important;
    background-color: whitesmoke;
  }
}

.editor-title-bar-secondary-configs {
  align-items: center;
}

.chapter-settings-menu {
  padding: 12px;
  border-radius: 6px;

  .chapter-settings-menu-wrapper {
    display: flex;
    align-items: center
  }

  .chapter-settings-menu-icon-holder {
    margin-right: 0.75em;
    display: flex;
    align-Items: center;
    width:12px;
    height: 12px;
  }

  .chapter-settings-selected-icon {
    display: none;
    padding-top: 6px;
  }

  .ant-dropdown-menu-item{
    padding-left: 4px;
  }

  .ant-dropdown-menu-item-selected {
    background: none !important;

    .chapter-settings-selected-icon {
      display: inline-block;
    }
  }
}

.thumbnail-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bl-info {
  margin-top: 2px;
  margin-bottom: 2px;
}

.bl-title {
  font-weight: bold;
  margin-top: 3px;
  margin-bottom: 4px;
}

.bl-footer-text {
  font-size: smaller;
  text-align: center;
  color: gray;
  margin-top: 2px;
}

.bl-terms {
  text-decoration: underline;
  color: gray;
}

.bl-powered-by {
  text-align: center;
  margin-top: 10px;
}

.justify-flex-end {
  display: flex;
  justify-content: flex-end;
}

.font-additional-info {
  font-family: initial;
  font-weight: normal;
}

.left-drawer-menu {
  margin-top: 20px;
  margin-right: 10px;
  width: 36px;
  height: 42px;
  background-color: black;
  border-radius: 0px 4px 4px 0px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.atticus-sidebar {
  display: flex !important;
  flex-direction: column;
  min-width: 280px;
  overflow-x: hidden !important;
  background-color: #FFFEFE;
}

.atticus-rhs {
  display: flex !important;
  flex-direction: column;
  overflow-x: hidden !important;
  background-color: #fff;
  border-left: #E8E8E9;
}

.right-menu-item.active{
  background-color: $light-gray-color1;
}
.sidebar-scroll {
  overflow-y: auto;
  flex: 1;
}

.margins-form-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.margins-heading {
  padding-right: 5px;
  margin-bottom: 0px !important;
}

.margin-input {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.margin-form-item {
  margin-bottom: 0px;
  margin-left: 10px;
}

.margins-divider {
  margin-top: 0px;
}

.gallery-btn {
  min-width: 100%;
  white-space: normal;
  height: auto;
  padding: 0.7rem 1rem;
  border-radius: 0.25em;
}

.pdf-exporter-btns {
  padding: 0px;
}


/*

  Authentication 

*/

.auth-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  max-width: 420px;
  margin: auto;

  .ant-form {
    width: 100%;
  }
}

.auth-card {
  opacity: 1;
  padding: 1rem;
  margin-top: -25vh;
  
  .inp {
      border:none;
      border-bottom: 1px solid $slick-bg-color;
      padding: 5px 10px;
      outline: none;
   }
  
   /*
  [placeholder]:focus::-webkit-input-placeholder {
      transition: text-indent 0.4s 0.4s ease; 
      text-indent: -100%;
      opacity: 1;
   }
   */
}
.auth-card-sign-up {
  opacity: 1;
  padding: 1rem;
}
.auth-logo__container {
  display: flex;
  justify-content: center;
}
.black-bar {
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  height: 56px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.black-bar svg {
  fill: white; /* Set the fill color of the SVG */
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
  align-self: center;
}

/*  Overview */

.at-book-view-switcher {
  display: flex;

  .ant-radio-button-wrapper {
    padding: 0;
    margin: .25em;
    height: 2rem;
    width: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    border-left-width: 1px;

    &::before {
      display: none;
    }

    svg {
      transform: scale(1.5);
    }
  }
}

.loading-book-list-item {
  pointer-events: none;
  cursor:not-allowed;
}

.book-list-item {
  .book-list-item-img {
    background-size: cover;
    height: 115px;
    width: 85px;
    border-radius: 5px;
    background-color: #D4E0F1;  
  }
  .book-list-item-btn {
    height: 115px;
    width: 85px;
    border-radius: 5px;
    cursor: pointer;
    background-color: $primary-bg-blue;
    &:hover{
      background-color: $primary-bg-blue-tint;
    }
  }
}

.at-table {
  table {
    border-width: 1px;
  }
}

.book-sort-select {
  .ant-select-selection-item::before {
    content: "Sort by: ";
    font-weight: 600;
  }
}

.book-filter-search {
  width: 400px;
}

.boxset-step-wizard {
   
  .ant-steps-item-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: row-reverse;
    border-bottom: 2px solid #3568BA;
    padding-bottom: .5em;
    margin-left: 0!important;
    opacity: .5;

    .ant-steps-item-title {
      color: #3568BA!important;
      font-weight: 600;
    }
  }

  .ant-steps-item  {
    &::after {
      display: none;
    }
  }

  .ant-steps-item-active {
    &::before {
      display: none;
    }
    .ant-steps-item-icon {
      visibility: hidden;
    }
    .ant-steps-item-container  {
      opacity: 1;
    }
  }
  .ant-steps-item-icon {
    font-size: 8px;
    height: 15px;
    width: 15px;
    line-height: 15px;
  }
}

.boxset-book-selection {
  .boxset-book-list {
    max-height: 300px;
    overflow: auto;
  }
}

.book-card-dropdown-overlay {
  .ant-dropdown-menu-title-content {
    margin-left: .5em;
  }
}

/* Bottom Bar */

.atticus-editor-bottom-bar {
  height: 32px;
  margin-right: 1em;
  font-weight: 600;
  color: $primary-bg-blue;
  margin: 0.25em 0em 0.25em 0em;
  margin-right: 1em;

  .disabled-item {
    opacity: 50%;
    pointer-events:none;
    cursor: not-allowed;
  }

  .divider {
    border-left: solid #D4E0F1;
    border-left: solid #D4E0F1;
  }

  .save-status {
    display: flex;
    align-items: flex-start;
    height: 100%;
    align-items: center;
    font-size: 0.875em;
  }

  .export-docx {
    display: flex;
    font-weight: 600;
    justify-content: center;
    height: 100%;
    align-items: center;

    .export-button{
      font-weight: 600;
      font-size: 0.875em;
    }
  }

  .timer {
    display: flex;
    font-size: 0.875em;
    font-weight: 600;
    justify-content: center;
    height: 100%;
    align-items: center;
    padding: 4px 15px 4px 15px;
    cursor: pointer;

    .timer-button{
      font-weight: 600;
      padding: 0;
    }
  }

  .word-count {
    display: flex;
    font-weight: 600;
    font-size: 0.875em;
    color: $subheading-text-color;
    justify-content: center;
    height: 100%;
    align-items: center;
    padding: 4px 0 4px 15px;

    .word-count-dropdown {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .word-count-type {
      font-weight: 600;
      color: $subheading-text-color;
    }

    .word-count-divider {
      margin-left: 5px;
      color: $subheading-text-color;
    }

    .word-count-words {
      font-weight: normal;
      margin-left: 5px;
      color: $subheading-text-color;
    }

    .word-count-arrow{
      margin-left: 5px;
      transition: transform .5s;
      align-items: flex-start;

      svg{
        display: flex;
      }
    }
  }

  .word-count:hover {
    .word-count-arrow svg{
      transform: rotate(180deg);
    }
  }
}

.timer-popover {
  width: 400;
}

.timer-popover-content{

  .popover-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      font-weight: 600;
      font-size: 1em;
    }
    svg {
      cursor: pointer;
    }
  }

  .input-label {
    font-weight: 600;
    font-size: 1em;
  }

  .start-button {
    justify-content: center;
  }
}

.timer-notification, .smart-quotes-notification{
  background-color: $primary-bg-mint-tint;
  width: 375px;
  box-shadow: 0px 0px 11px #00000029;
  border-radius: 4px;
  color: white;

  .ant-notification-notice-message {
    display: none;
  }

  .action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .start-button {
      color: white;
      font-size: 1em;
      font-weight: 600;
    }
  }

  .destroy {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }

  .title {
    font-size: 1.125em;
    font-weight: 600;
  }

  .description {
    font-size: 1em;
    font-weight: normal;
  }
}


// TOC Page
.toc-page-layout {
  height: 100%;
}

.att-toc-editor {
  background-color: $default-bg-color;
  border-radius: 4px;
  padding: 0em 1.5em;
  margin: 1.3rem;
  height: 95%;

  .chapter-type {
    margin-bottom: 0;
    text-transform: capitalize;
    font-size: 0.85em;
    font-weight: 400;
    line-height: 1;
    color: rgba(0, 0, 0, 0.5);
  }

  .title-input {
    width: 100%;
    font-size: 1.5rem;
    font-weight: 700;
    padding-bottom: 0;
    border: none;
    outline: none;
  }

    .title-form-card {
      border: none;
      border-bottom: solid 1px #E8E8E9;
      padding: 1.5em 0em;
      
      .title-form-item {
        margin: 0;
      }
    }
  .toc-setting-form{
    height: 100%;
  }
}
.att-toc-list {

  .item {
    position: relative;
    background: inherit;
    max-width: 100%;
    min-width: 300px;
    margin: 0 auto;
    font-size: 1rem;
    color: $subheading-text-color;
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    padding-Left: 40px;

    &.not-included {
      opacity: .4;
    }

    .name {
      background: inherit;
      display: inline;
      z-index: 1;
      padding-right: 1rem;
    }
    .lines {
      flex: 1 1;
      width: 100%;
      position: relative;
      top: 0.25rem;
      border-bottom: 2px dotted $black-bg-color;
    }
    .number {
      background: inherit;
      min-width: 3rem;
      bottom: 0;
      right: 0;
      padding-left: 1rem;
      text-align: right;
      z-index: 2;

      &:after {
        content: "";
      }
    }
    
  }
  
  .endnote-wrapper{
    display: flex
  }

  .endnote {
    display: inline-block
  }
}

.att-toc-container {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  overflow-x: auto;

  .ant-card {
    flex: unset;
    border: 1px solid #E8E8E9;
    border-radius: 4px;
  }

  .ant-card-head {
    background-color: #E8E8E9; 
    color: #171D21;
    font-size: 1.125rem;
    font-weight: 600;
  }

  .type {
    text-transform: capitalize;
  }

  .checkboxes {
    display: flex;
    margin-top: 2px;
    gap: 15px;
  }

  > * {
    flex: 1 1 0;
  }

  .ant-form-item-label{
    font-weight: bold;
  }
}

.setting-area {
  border-left: 1px solid #E8E8E9;
  border-right: 1px solid #E8E8E9;

  .setting-area-col {
      width: 100%;
      background-color: $default-bg-color;
      border-radius: 5px;
      padding: 1em 0em 1em;
      box-shadow: $default-box-shadow;
      margin-bottom: 1em;
      height: 100%;

      .heading-divider .ant-divider-horizontal {
        margin-bottom: 0px;
        margin-top: 16px;
      }

      .close-btn {
        padding: 0;
        width: max-content;
      }

      .scroll-container {
        overflow-X: hidden !important;

        &::-webkit-scrollbar{
          width: 8px;
          background-color: transparent;
        }
      
        &:hover::-webkit-scrollbar{
          background-color: #E8E8E9;
          border-radius: 4px;
        }
      
        &:hover::-webkit-scrollbar-thumb {
          background-color: #3568BA;
          border-radius: 4px;
        }
      }
  }

  .setting-area-title h4 {
    font-size: 1.125em;
    font-weight: 600;
    color: "#171D21";
    float: left;
  }

  .setting-area-title {
    display: flex;
  }

  .info-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.5em;
    padding: 5px 0px;
  }
}

.setting-area-header{
  padding: 0em 1.5em;
}

.setting-area-form {
  padding: 1em 1.5em;
  width: 100%;

  .ant-form-item {
    row-gap: 0px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 0.5em;
  }

  .ant-form-item .ant-col {
    max-width: 100%;
  }

  .setting-form-label {
    font-size: 1.143em;
  }

  .ant-divider-horizontal {
    margin: 1em 0;
  }

  .setting-form-row {
    padding-top: 1em;
  }
}

.editor-wrapper {
  display: flex;
  height: 100%;
  flex: 1;
}

.searchResults{
  color: $primary-bg-mint;
}

.arrow-btn{
  padding: 2px;
  height: 16px !important;
}

.sync-all-chapters-fnr-btn{
  margin-bottom: 1.5em;
  margin-top: 0.5em;
}

.settings-tabs{
  height: 38px;
  
  .settings-tab-item {
    text-align: center;
    border: 1px solid #E8E8E9;
    height: 40px;

    .setting-tab-btn{
      width: 100% !important;
      height: 100% !important;
      display: flex;
      justify-content: center;

      .atticus-button-text{
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .info-icon-wrapper{
        display: flex;
        margin-left: 4px;
      }
    }
  }

  .settings-tab-item.active {
    border-bottom: 4px solid $primary-bg-mint;
    background-color: #F5F5F5;
  }
  .settings-tab-item .atticus-button .atticus-button-text{
    color: #454A4D;
    font-size: 16px;
  }
  .settings-tab-item.active .atticus-button .atticus-button-text{
    color: $primary-bg-mint;
    font-size: 16px;
    font-weight: 600;
  }
  
}

/*

  Formatting Themes   

*/
.center-vertical {
  align-items: center;
}

.thm-section{
  h2{
    margin-bottom: 1em;
  }
  h4{
    margin-bottom: 0.4em;
  }
}
.theme-cards .ant-list-items {
  margin-top: 1.5em;
  display: grid;
  flex-wrap: wrap;
  margin: auto;
  width: 100%;
  grid-gap: 1em;
  columns: 5 auto;
  grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));

  .theme-card {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    margin-bottom: .5em;
    position: relative;
    cursor: pointer;
    flex-direction: column;
    align-items: center;
    width: 175px;

    .theme-card-container {
      height: 220px;
    }

    &.active {
      .theme-element  {
        border: 2px solid #231F20!important;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
      }

    }

    &.create-new-theme {
      &.active {
        .theme-element  {
          border: 2px solid #3568BA!important;
          box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
        }
      }
    }

    .theme-card-overlay {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      cursor: pointer;
    }

    .theme-element {
      border-radius: 8px;
      border: 1px solid #EBEBEB;
      overflow: hidden;
      width: 100%;
      position: relative;

      .create-new-theme-preview-card{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 20px;

        .create-new-theme-icon{
          font-size: 28px;
          margin-bottom: 15px;
          color: #3568BA;
        }

        .create-new-theme-label{
          font-size: 16px;
          font-weight: 600;
          width: 70%;
          color: #3568BA;
        }
      }
    }

    .theme-card-bottom {
      margin-top: .5em;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      font-size: 1em;

      .theme-card-name-wrapper {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 600;
      }
    }
    .theme-favourite {
      position: absolute;
      width: 24px;
      height: 24px;
      bottom: 12px;
      right: 12px;
      z-index: 10;
      background: #FFFFFF;
      box-shadow: 0px 1px 12px #00000014;
      border: 1px solid #DBDCDD;

      &:hover {
        box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.1);
      }

      & svg {
        color: #3568BA;
        width: 12px;
        height: 12px;
      }
    }
  }
}

.theme-kebab-delete {
  margin-left: 6px;
}

.ob-btns {
  width: 100%;
  max-height: 60vh;
  overflow-y: scroll;

  .ant-radio-group {
    display: flex;
    flex-wrap: wrap;

    .ant-radio-button-wrapper {
      width: 23%;
      margin: 1%;
      height: 50px;
      display: flex;
      align-items: center;
      border-radius: 8px !important;
      border: none;
      box-shadow: none;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    .ant-radio-button-wrapper-checked {
      background-color: $default-area-bg-color;
    }
    ::before {
      display: none !important;
    }
  }
}


.placement__theme--box {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  padding: 0.6rem 0.7rem;
  background: $default-bg-color 0% 0% no-repeat padding-box;
  // box-shadow: 0px 0px 20px $black-bg-with-alpha;
  border-radius: 5px;
  height: 100px;
  width: 140px;
  margin-bottom: 1rem;
  border: 2px solid $common-border-color;
  cursor: pointer;

  .top__part--section {
    height: 100%;
    position: relative;
    padding: 1rem;
    padding-right: 0;
    padding-left: 0;
    font-size: .75rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 5px;
    font-weight: 600;
    border-radius: 20px;
  }

  &:not(:last-child) {
    margin-right: 1rem;
  }
}

.theme__image--wrapper {
  width: 100%;
}

.theme-config-wrapper {
  .generic__black--button {
    height: 28px !important;
    z-index: 200;
  }
  .generic__gray--button {
    height: 28px !important;
    margin-right: 10px;
    z-index: 200;
  }

  .mythemes__header--block {
    padding-left: 5px;
    flex-wrap: nowrap;
    overflow-x: hidden;
    justify-content: flex-start;

    ::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
        background: transparent;
    }

    .ant-col {

      &:last-child {
        max-width: 1100px;
        display: flex;
        overflow-x: auto;
      }
    }
  }
}

.large-print-checkbox {
  margin-bottom: 0px !important;
  .ant-checkbox-wrapper{
    color: rgba(0, 0, 0, 0.85) !important;
  }
}

.print-font {
  width: 100px
}

.theme-tabs{
  width: 560px;
}

.bb-logo {
  width: 300px;
}

.bb-logo-div {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.theme-container {
  flex: 1;
  padding: 0 1em; 

  .theme-header {
    height: 80px;
  }

  .theme-title-section {
    padding: 1.5em 1.5em 0em;
    background-color: white;
    border-radius: 4px;
    margin-bottom: 1em;
  }

  .content {
    flex: 1;
    height: calc(100vh - 150px);
    border-radius: 5px;
    overflow-y: auto;

    display: flex;
    flex-direction: column;
    align-items: stretch;
  }


  .theme-container-top{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 45px;

    h2.current-theme-title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .theme-section {
    background-color: $default-bg-color;
    border-radius: 5px;
    width: 100%;
    box-shadow: none;
    border: none!important;

    .ant-collapse-content-box {
      padding: 2.5rem!important;
    }

    .theme-section-header {
      padding: 1em;
      
      .scroll-container {
        overflow: hidden;

        &::-webkit-scrollbar{
          width: 8px;
          background-color: transparent;
        }
      
        &:hover::-webkit-scrollbar{
          background-color: #E8E8E9;
          border-radius: 4px;
        }
      
        &:hover::-webkit-scrollbar-thumb {
          background-color: #3568BA;
          border-radius: 4px;
        }
      }
    }

    .theme-listing {
      padding: 0 1em 1em;
    }

    .theme-section-tab-content {
      background-color: white;
      border-radius: 4px;
      min-height: 100%;
      padding: 1.5em;
    }

    .ant-list-pagination {
      color: black;
      display: flex;
      padding-bottom: 2em;
      margin-top: 0 !important;
      justify-content: left;
      &:hover {
        color: white;
      }
      .ant-pagination-prev,
      .ant-pagination-next {
        display: none;
      }
      .ant-pagination-item {
        background-color: #EBEBEB;
        font-weight: bold;
        min-width: 50px;
        padding: 0;
        height: 25px;
        border: none !important;
        box-shadow: none !important;
        a {
          line-height: 1;
          padding: .4em;
          height: 25px;
        }
      }
      .ant-pagination-item-active {
        background-color: #3568BA;
        a {
          color: white !important;
        }
      }
    }
  }

  .default-scenebreak-text{
    font-size: 2rem;
  }
  .theme-form {
    .at-vertical-tabs {
      overflow: hidden;
    }

    .ant-collapse {
      background-color: rgba(224, 224, 224, 0)!important;
      box-shadow: none;

      .ant-collapse-header {
        font-size: 1.25rem;
        line-height: 3rem!important;
        font-weight: $font-bold;
        position: sticky!important;
        border-radius: 5px;
        top: 0;
        background: white;
        z-index: 10;
        border-bottom: 1px solid whitesmoke;
      }
    }
  }

  .theme-sb-default {
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover, &.ant-radio-button-wrapper-checked  {
      .sb-default {
        color: black;
      }
    }

    .sb-default {
      font-size: 2em;
      display: flex;
      height: 30px;
    }
  }
}

.theme-thumbnail {
  display: flex;
  flex-direction: column;
  padding: 1em;
  position: relative;
  width: 90%;
  height: 100%;
  margin: auto;

  .thumbnail-header {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0.25em;
  }

  .thumbnail-body {
    flex: 1;

    p {
      font-size: 4px;
      margin-bottom: 1em;
    }
  }
}

.at-image-selector {
  width: 100%;
  
  .atticus-button {
    padding: 0!important;
  }
  .at-image-selector-image {
    position: relative;
    height: 250px;
    width: 100%;
    margin: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    .at-image-selector-delete-btn {
      all: unset;
      position: absolute;
      top: 0.5em;
      right: 0.5em;
      background-color: white;
      padding: 0.25em 0.5em;
      border-radius: 4px;
      height: 1.5em;
      width: 1.5em;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 3px 7px #00000085;
    } 
  }
  .at-image-selector-previewer {
    background-color: $primary-background;
    color: #3568ba;
    font-weight: 600;
    font-size: 1rem;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}


.draggable-section {
  min-height: 4rem;
}

.context-menu {
  position: fixed;
  z-index: 10;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%) !important;
  border-radius: 4px;
}

.book-title-card-container {
  height: 175px;
  border-bottom: 1px solid rgba(53, 104, 186, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.book-title-card {
  top: 0px;
  background: $background-blue;
  z-index: 20;
  align-items: center;
  color: #171D21;
  max-width: 280px;

  .book-title-card-inner{
    border-radius: 0px;
    padding: 1.5em;
    width: 100%;

    &:hover{
      background-color: #FFFFFF;
    }

    &.active{
      background-color: #FFFFFF;
      color: #3568BA;
    }

    .book-title-card-edit-icon-container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 100%;
    }
  }

  .book-title {
    font-size: 1.8rem;
    font-weight: 600;
    color: inherit;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .book-subtitle{
    font-size: 1.1rem;
    font-weight: 400;
    color: inherit;
  }
}

.list-section-divider{
  margin-top: 1rem!important;
  margin-bottom: 0.5rem!important;
}

.chapter-block-item {
  user-select: none;
  display: flex;
  flex-direction: row;
  position: relative;
  cursor: pointer !important;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  font-size: 1.125rem;
  font-weight: 400;
  padding-right: 5px;

  &.master-page-block{
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 5px;
    background-color: #F3F3F3;
  }
  
  &.placeholder {
    height: 2px;
    background: transparent;
    pointer-events: none;
    flex: 1;
    background: transparent;
   
    &.visible{
      background: #c6e1ff;
    }
  }

  a {
    color: inherit;
    width: 100%;
  }

  .number {
    margin-right: 0.4em;
    display: block;
    width: 1.6em;
  }

  .chapter-block-item-container {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    align-items: baseline;
    flex: 1 1 0;
    .chapter-block-item-info-container{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: baseline;
      padding-top: 4px;
      padding-bottom: 4px;
      .chapter-block-item-chapter-extra{
        margin-right: 5px;
      }
      .chapter-block-item-chapter-name-cover{
        .chapter-block-item-chapter-name{
          font-size: 16px;
          color: #171D21;
          &.frontmatter {
            font-weight: 600;
          }
          &.active {
            color: #3568BA !important;
          }
        }
      }
    }
  }

  .text-area {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex: 1;
    color: rgba(0, 0, 0, 0.9);
    padding: 0.5rem 0;
  }

  &:hover,
  &.hover {
    .action-group {
      opacity: 1;
    }
  }

  .action-icon {
    height: 1em;
  }

  .action-group {
    position: absolute;
    right: 0;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    opacity: 0;
    background: rgba(255, 255, 255, 1);
    padding: 0px 0.4em;
    box-sizing: border-box;
    border-radius: 4px;
  }
  .action-btn {
    padding: 0px 0.4rem !important;
    display: flex;
    max-height: 30px;
    justify-content: center;
    align-items: center;
  }
}

.chapter-block-item-icon-container{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 0.5em;
  padding-top: 0.25em;
}

.chapter-block-icon{
  display: flex !important;
  align-items: center;
  svg{
    height: 16px;
    width: 16px;
  }
}

.sidebar-body-seperator{
  display: flex;
  align-items: center;
  padding-left: 0.8rem;
  .sidebar-body-seperator-title{
    font-size: 16px;
    color: #171D21;
    font-weight: 600;
  }
  .sidebar-body-seperator-main-divider-cover{
    flex: 1 1 0;
    margin-left: 1rem;
  }
  .sidebar-body-seperator-sub-divider-cover{
    flex: 1 1 0;
    padding-right: 2em;
    .sidebar-body-seperator-sub-divider{
      margin: 0.2rem 0;
    }
  }
}

.action-bar-container {
  display: flex;
  flex-direction: row;
  .action-bar-more-btn-container {
    padding-left: 5px;
  }
}

.master-pages-menu-popup ul{
  overflow-y: auto;
  max-height: 80vh;
  height: auto;
  li {
    padding: 0;
  }
}

.action-bar-masterpage-menu-item{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 5px;
  padding-right: 5px;
  .action-bar-masterpage-title-cover {
    height: 35px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 5px;
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    &:hover {
      background-color: #EBEBEB;
    }
    .action-bar-masterpage-title{
      font-size: 14px;
      margin: 0;
    }
  }
  .action-bar-masterpage-menu-icon-cover{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    height: 35px;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    &:hover {
      background-color: #EBEBEB;
    }
    .action-bar-masterpage-menu-icon{
      display: flex !important;
      align-items: center;
      svg{
        height: 16px;
        width: 16px;
      }
    }
  }
}

.edit-book-wrapper{
  height: calc(100vh - 60px);
  width: 100%;
  background-color: #0C4BB51F;
  .edit-book-scroller{
    height: 100%;
    overflow-y: scroll;
    .edit-book-form-cover{
      padding: 1em 3em 1em 3em;
    }
    .edit-book-info-cover{
      display: flex;
      flex-direction: column;
      padding: 1em 3em 1em 0em;
    }
  }
}

.book-stat-cover{
  background-color: #FFFFFF;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;
  .book-stat-title{
    font-size: 1.1em;
    color: #171D21;
    margin-bottom: 10px;
  }
  .book-stat-item-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    .book-stat-item{
      min-width: 40%;
      padding: 15px 10px;
      border: 1px solid #E8E8E9;
      border-radius: 4px;
      .book-stat-number{
        color: #3568BA;
        line-height: 1;
        margin-bottom: 5px;
      }
      .book-stat-label{
        font-size: 0.8em;
        line-height: 1;
        color: #171D21;
      }
    }
  }
}

.export-container-cover{
  background-color: #FFFFFF;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;
  .export-container-title{
    font-size: 1.1em;
    color: #171D21;
    margin-bottom: 10px;
  }
  .export-container-item-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    .export-container-button {
      min-width: 30%;
    }
  }
}

.snapshot-container-cover{
  background-color: #FFFFFF;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;
  .snapshot-container-title{
    display: flex;
    align-items: center;
    gap: 0.25rem;
    font-size: 1.1em;
    color: #171D21;
  }
  .snapshot-container-item-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    .snapshot-container-button {
      min-width: 30%;
    }
  }
}

.edit-book-ant-form{
  .ant-form-item-label{
    padding: 0px;
  }
  .edit-book-form-label{
    display: flex;
    align-items: center;
    gap: 0.25rem;
    color: #171D21;
    font-size: 0.9rem;
    font-weight: 600;
  }
  .edit-book-input-field{
    border-radius: 4px;
  }
}

.edit-book-image-section-wrapper{
  display: flex;
  .edit-book-image-dnd-container{
    max-width: 250px;
    max-height: calc(250px * 1.38);
    min-width: 100px;
    min-height: calc(100px * 1.38);
    border-radius: 4px;
    .edit-book-image-cover {
      height: 100%;
      width: 100%;
      border-radius: 4px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      .edit-book-image-overlay{
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        flex-direction: column;
        border-radius: 4px;
        padding: 0.75em 0.5em;
      }
    }
    .edit-book-image-dnd-text-highlight{
      color: #3363B9;
    }
    .edit-book-spinner{
      font-size: 24px;
    }
  }
  .edit-book-image-button-container{
    max-width: 250px;
    min-width: 100px;
    padding-top: 15px;
    margin-top: 15px;
    button{
      margin-bottom: 5px;
    }
  }
}

.edit-publisher-image-section-wrapper{
  display: flex;
  .edit-publisher-image-dnd-container{
    max-width: 250px;
    max-height: 250px;
    min-width: 100px;
    min-height: 100px;
    border-radius: 4px;
    .edit-publisher-image-cover {
      height: 100%;
      width: 100%;
      border-radius: 4px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      .edit-publisher-image-overlay{
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        flex-direction: column;
        border-radius: 4px;
        padding: 0.75em 0.5em;
      }
    }
    .edit-publisher-image-dnd-text-highlight{
      color: #3363B9;
    }
    .edit-publisher-spinner{
      font-size: 24px;
    }
  }
  .edit-publisher-image-button-container{
    max-width: 250px;
    min-width: 100px;
    padding-top: 15px;
    margin-top: 15px;
    button{
      margin-bottom: 5px;
    }
  }
}

/* xs */
@media only screen and (max-width: 575px) {
  .edit-book-wrapper{
    .edit-book-scroller{
      .edit-book-info-cover{
        display: flex;
        flex-direction: column;
        padding: 1em 3em 1em 3em;
      }
    }
  }
  .edit-book-image-section-wrapper{
    .edit-book-image-dnd-container{
      width: 50vw;
      height: calc(50vw * 1.38);
    }
    .edit-book-image-button-container{
      width: 50vw;
    }
  }
  .edit-publisher-image-section-wrapper{
    .edit-publisher-image-dnd-container{
      width: 50vw;
      height: 50vw;
    }
    .edit-publisher-image-button-container{
      width: 50vw;
    }
  }
}
/* sm */
@media only screen and (min-width: 576px) {
  .edit-book-wrapper{
    .edit-book-scroller{
      .edit-book-info-cover{
        display: flex;
        flex-direction: column;
        padding: 1em 3em 1em 3em;
      }
    }
  }
  .edit-book-image-section-wrapper{
    .edit-book-image-dnd-container{
      width: 35vw;
      height: calc(35vw * 1.38);
    }
    .edit-book-image-button-container{
      width: 35vw;
    }
  }
  .edit-publisher-image-section-wrapper{
    .edit-publisher-image-dnd-container{
      width: 35vw;
      height: 35vw;
    }
    .edit-publisher-image-button-container{
      width: 35vw;
    }
  }
}

/* md */
@media only screen and (min-width: 768px) {
  .edit-book-wrapper{
    .edit-book-scroller{
      .edit-book-info-cover{
        display: flex;
        flex-direction: column;
        padding: 1em 2em 1em 1em;
      }
      .edit-book-form-cover{
        padding: 1em 1em 1em 2em;
      }
    }
  }
  .edit-book-image-section-wrapper{
    .edit-book-image-dnd-container{
      width: 30vw;
      height: calc(30vw * 1.38);
    }
    .edit-book-image-button-container{
      width: 30vw;
    }
  }
  .edit-publisher-image-section-wrapper{
    .edit-publisher-image-dnd-container{
      width: 30vw;
      height: 30vw;
    }
    .edit-publisher-image-button-container{
      width: 30vw;
    }
  }
}

/* lg */
@media only screen and (min-width: 992px) {
  .edit-book-wrapper{
    .edit-book-scroller{
      .edit-book-info-cover{
        display: flex;
        flex-direction: column;
        padding: 1em 2em 1em 1em;
      }
      .edit-book-form-cover{
        padding: 1em 1em 1em 2em;
      }
    }
  }
  .edit-book-image-section-wrapper{
    padding-left: 20px;
    .edit-book-image-dnd-container{
      width: 20vw;
      height: calc(20vw * 1.38);
    }
    .edit-book-image-button-container{
      width: 20vw;
    }
  }
  .edit-publisher-image-section-wrapper{
    padding-left: 20px;
    .edit-publisher-image-dnd-container{
      width: 20vw;
      height: 20vw;
    }
    .edit-publisher-image-button-container{
      width: 20vw;
    }
  }
}

/* xl */
@media only screen and (min-width: 1200px) {
  .edit-book-image-section-wrapper{
    padding-left: 20px;
    .edit-book-image-dnd-container{
      width: 17vw;
      height: calc(17vw * 1.38);
    }
    .edit-book-image-button-container{
      width: 17vw;
    }
  }
  .edit-publisher-image-section-wrapper{
    padding-left: 20px;
    .edit-publisher-image-dnd-container{
      width: 17vw;
      height: 17vw;
    }
    .edit-publisher-image-button-container{
      width: 17vw;
    }
  }
}

.book-brush-button {
  background: linear-gradient(to right, #5F00BD , #F60040) !important;
  color: white !important;
  white-space: normal;
}


.all-body-button {
  height: 10px;

}

.chapter-hidden{
  opacity: 0.4;
}

.rhs-item {
  width: 48px;
  align-items: center;
  height: 64px;
  justify-content: center;
  display: flex;  
}

.item-bottom {
  margin-top: auto;
}

.ant-divider-horizontal {
  &.rhs-divider {
    width: 100%;
    margin: 0px;
    position: absolute;
  }
} 

/* Goals */

.setting-area-form .at-form-input.secondary{
  width: 100% !important;
}

.goal-setting-check-btns {
  width: 100%;
  max-width: 720px;
  padding: 2px;

  .ant-form-item-control-input {
    height: 24px !important;
    min-height: 24px !important;
  }

  .ant-checkbox-group {
    display: flex;

    .ant-checkbox-button-wrapper {
      width: 10%;
      height: 40%;
      margin: 4px 2px;
      color: #DBDCDD;
      border-radius: 4px !important;
      border-left-width: 1px;
      text-align: center;
      padding: 2px;
      margin: 6px;
    }

    label.ant-checkbox-wrapper {
      width: 24px;
      margin-right: 10px;

      & span:not(.ant-checkbox) {
        display: none;
      }
    }

    .ant-checkbox-inner {
      display: none;
    }

    .ant-checkbox-wrapper:nth-child(1)::after, .ant-checkbox-wrapper:nth-child(7)::after {
      content: "S";
    }

    .ant-checkbox-wrapper:nth-child(2)::after {
      content: "M";
    }

    .ant-checkbox-wrapper:nth-child(3)::after,
    .ant-checkbox-wrapper:nth-child(5)::after {
      content: "T";
    }

    .ant-checkbox-wrapper:nth-child(4)::after {
      content: "W";
    }

    .ant-checkbox-wrapper:nth-child(6)::after {
      content: "F";
    }

    .ant-checkbox-wrapper::after {
      display: inline-block;
      content: " ";
      width: auto;
      background: #FFFFFF;
      color: $secondary-bg-black;
      width: 24px;
      height: 24px;
      border-radius: 4px !important;
      border: 1px solid #DBDCDD;
      text-align: center;
      line-height: 24px;
      font-weight: 600;
    }

    .ant-checkbox-wrapper-checked.ant-checkbox-wrapper::after {
      background: $primary-bg-mint;
      color: #FFFFFF;
    }

    .ant-checkbox-checked .ant-checkbox-inner:after {
        width: 20px;
          height: 20px;
          display: block;
          content: "S";
    }
  }
}

.goals-progress-percentage {
  color: $secondary-bg-black;
  font-weight: 600;
  font-size: 16px;
}

.total-words {
  color: #171D21;
  font-weight: 600;
  font-size: 1rem;
}

.words-per-day {
  color: #A2A5A6;
  font-size: 1rem;
}

.goals-menu-item:hover {
  background-color: $secondary-btn-green-hover;
  color: $primary-bg-mint;
  font-weight: 600;
}

.goals-target-title {
  color: $secondary-bg-black;
  font-weight: 600;
  font-size: 14px;
}

.goal-percentage {
  font-weight: 600;
  display:inline;
  float:left;
}

.goal-complete-message {
  color: #00be95;
  padding-bottom: 10px;
}

.new-goal-btn {
  padding: 0;
  height: fit-content !important;
}

.edit-goal-icon-btn {
  padding: 0;
  height: 16px;
}

.goals-setting-menu {
  border-radius: 6px;
  border: 1px solid #E8E8E9;
  box-shadow: 4px 12px 17px #171D211F;
}

.total-words-negative {
  color: #FE2121;
  font-weight: 600;
  font-size: 16px;
}

.deleted-word-msg {
  color: #FE2121;
  font-size: 14px;
  background-color: #FFF3F3;
  border-radius: 4px;
}

.goals-word-count-text {
  color: #A2A5A6;
  word-wrap: break-word ;
  font-size: 14px;
}

.goal-words {
  font-size: 14px;
}

/* writing habbit*/

.writing-habit-streak-day-count {
  font-weight: 600;
  display: inline-block;
}

.writing-habit-streaks-container {
  margin-top: 1em;
}

.writing-habit-streak-item {
  border: 1px solid #E8E8E9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3px;
  border-radius: 5px;

  .writing-habit-streak-item-label {
    color: #171D21;
    font-weight: 400;
    font-size: 14px;
  }

  .writing-habit-streak-item-title {
    color: #00BE95;
    font-size: 16px;
  }
}

.writing-habbit-word-counter-wrapper {
  width: 100%;
  padding-bottom: 10px;
}

.writing-habit-configuration-form {
  .warn-edit {
    color: #FAAC14;
    font-size: 12px;
  }
}

.writing-habit-monthly-tracker {
  margin-top: 1em;

  .monthly-tracker-heading {
    color: $subheading-text-color;
    font-size: 14px;
    font-weight: 500;
  }

  .ant-picker {
    padding: 0 !important;
  }

  .ant-picker-input > input {
    color: rgba(23, 29, 33, 0.6);
    font-size: 14px;
  }
}


/* Link Modal styles */

.link-btn-group {
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  text-align: center;

  .ant-radio-button-wrapper {
    width: 100%;
    font-size: 16px;
  }

  .link-rd-btn.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background-color:$primary-bg-mint;
    border-color: $primary-bg-mint;
    border-radius: 4px;
    color: #FFFFFF;
  }
}

.visit-link-btn{
  border-color: #DBDCDD;
  line-height: 40px !important;
}

.form-item-container {
  margin: 0;
  font-size: 16px;
}

.link-btn-group .ant-radio-button-wrapper + .ant-radio-button-wrapper::before {
  display: none;
}


.link-modal-btn{
  font-size: 16px;
}

.link-popover-content {
  .link-popup-div {
    .btn-green-edit {
      width: 113px;
      height: 38px;
      font-size: 16px;
      border-radius: 4px;
      border: none;
      color: $primary-bg-mint;
      background-color: #F2FCFA;
    }
    .btn-visit-link {
      width: 113px;
      height: 38px;
      font-size: 16px;
    }
  }
}

.txt-with-btn {
  display: flex !important;
  .visit-link-btn{
    font-size: 16px;
  }
}

.selector-internal-link{
  font-size: 16px;
}



/* Title pages (Book Title, Volumes, Parts)*/

.title-page-area, .fullpage-img-area {
  background-color: $default-bg-color;
  border-radius: 8px;
  padding: 1em 2em;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
  margin: 1.3rem;
  overflow-y: auto;
  overflow-x: hidden;
  height: 75%;
  min-Width: 700;

  .att-editor-bar{
    margin-top: 0;
  }
}

.title-page-body-wrapper{
  margin-top: 24px;
}

.title-page-container {
  max-width: 480px;
  min-width: 480px;
}

.title-page-divider {
  margin-top: 1.125em !important;
  margin-bottom: 0.75em !important;
}

.title-page-image-previewer{
  height:160px !important;
  margin-bottom: 8px !important;
}

.title-page-text-holder{
  justify-Content:center;
  font-Size:18px
}

.title-page-file-upload-container{
  margin-bottom: 8px;
}

.title-page-form-container {
  margin-top: 1rem ;
}

.title-page-comment-label {
  font-size: 0.9rem;
}

.title-page-content-label {
  font-size: 1.2rem;
}

.title-page-card {
  margin: 1.3rem;
  border-Radius: 4px;
  
  .title-page-card-content{
    display: contents;
  }
}

.title-page-card-button-container {
  display: flex;
  flex-wrap: wrap;
  justify-Content: flex-end;
  gap: 10px;
}

.title-page-form {
  .ant-upload.ant-upload-drag{
    height: 160px !important;
  }
}
.delete-img-btn {
  width: auto;
  border-radius: 4px;
}
// title bar 

.title-bar-wrapper{
  width:100%
}

.title-bar-image-container{
  display: flex;
  justify-content:center;
  align-items:center;
  height: 50px;
  width: 50px;
  margin-bottom: 5px;
  margin-right: 12px;
  background-color: $blue-bg-tint;
  border-radius: 4px;
  background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  // border: '1px solid #ccc', // Add border for visualization, you can remove this in production
}

.title-bar-icon-holder {
  margin-right: 1.25em;
  display: flex;
  align-items: center;
}

.title-bar-padding{
  padding-left: 1.5em;
}

.tite-bar-title-input {
  display: flex;
  align-items: center;
  max-width: 100%;
}

.tite-bar-title-input-chapter-settings {
  max-width: 480px;
}

.title-bar-image-previewer{
  height: 50px;
  width: 50px;
  background-color: #3363B9;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 4px;
  margin-right: 12px;
  margin-bottom: 5px;

  .title-bar-image-previewer-container{
    height: 100%;
    width: 100%;
    
    display: none;
    // display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #171D21;
    opacity: 80%;
    border-radius: 4px;
  }

  &:hover {
    .title-bar-image-previewer-container{
      display: flex;
    }
  }

  .image-toolbar-image-previewer-name{
    size: 0.8rem;
    color: white;
    margin-bottom: 1em;
  }

  
}

.title-page-area, .fullpage-img-area {
  .info-icon-wrapper{
    display: flex;
    margin-left: 0.25em;
    justify-content: center;
    align-items: center;
  }
}

/* Profile settings styles */

.profile-page{
  background-color: #F1F5FC;
  padding: 1em 1.5em;

  .profile-title {
    font-size: 24px;
    font-weight: 600;
    padding: 0 1em;
  }
  
  .profile-setting-container {
    padding: 1.5em 1em 0em 0em;
    background-color: $default-bg-color;
    border-radius: 4px;
    width: 100%;
    
    .profile-container {
      border-radius: 4px;
      width: 100%;
      padding-top: 1em;
      margin-bottom: .5em;
      box-shadow: none;
      border: none!important;
      height: 100vh;
      .ant-tabs-tab {
        font-size: 16px;
        width: 170px;
        display: block;
      }

      .scroll-container {
        overflow: hidden;

        &::-webkit-scrollbar{
          width: 8px;
          background-color: transparent;
        }
      
        &:hover::-webkit-scrollbar{
          background-color: #E8E8E9;
          border-radius: 4px;
        }
      
        &:hover::-webkit-scrollbar-thumb {
          background-color: #3568BA;
          border-radius: 4px;
        }
      }
      .ant-tabs-ink-bar{
        background-color: transparent;
      }

      .ant-tabs-extra-content {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 140px;
        position: absolute;
        
        .logout-btn-div{
          .logout-btn {
            width: 170px;
            font-size: 16px;
            font-weight: 600;  
            .atticus-button-text{
              margin-left: 0px;
            }
          }
        }
      }

      .ant-tabs-nav-list {
        width: 220px;
      }

      .ant-tabs-tab-btn {
        width: 170px;
      }

      .ant-tabs-tabpane{
        background-color: #F1F5FC;
        border-radius: 4px;
        overflow: auto;
        height: 100%;
      }
      .ant-tabs-tabpane:hover::-webkit-scrollbar-thumb {
        background-color: #3568BA;
      }

      .my-accoutn-card {
        width: 100%;
        padding: 0.8em 0.5em;

        .title{
          font-size: 20px;
          font-weight: 600;
        }
        .profile-name{
          font-size: 24px;
          font-weight: 600;
        }

        .pr-avatar {
          position: relative;
          display: table;
        }
        .avatar-icon {
          font-size: 50px;
        }

        .form-field {
          padding: 0em;
        }

        .ant-form-item-no-colon{
          font-size: 16px;
          font-weight: 600;
        }

        .profile-image{
          width: 120px;
          height: 120px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 4px solid white;

          .profile-text-p {
            font-size: 3.5em;
            font-weight: 600;
          }
        }

        .profile-image-loader {
          @extend .profile-image;
          border-radius: 100%;
        }

        .upload-icon {
          position: absolute;
          top: 0;
          right: 0;
          transform: translate(10%, 150%);
          width: 50%;
          height: 50%;
        }

        .update-button {
          width: 101px;
          font-size: 16px;

          .atticus-button-text{
            margin-left: 0px;
          }
        }

        .top-password-row{
          margin-top: 15px;
        }
        .password-row {
          margin-top: 10px;
        }
        .password-imput-field {
          border-radius: 4px;
        }
      }
    }
  }
}
//Delete Modal

.delete-modal-bottom-padding{
  margin-bottom: 1em;
}

// Previewer - Toolbar
.ant-row.previewer-toolbar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.previewer-select {
  width: 180px;
}

.font-size-slider {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.25em 0.75em;

  .slider {
    width: 100%;
    margin: 0 1em;
  }

  .small-a {
    font-size: 0.75rem;
    font-weight: 600;
    color: $subheading-text-color;
  }

  .big-a {
    font-size: 1.25rem;
    font-weight: 600;
    color: $subheading-text-color;
  }
}

.previewer-actions {
 
  .epub-chapter-navigation {
    display: flex;
    gap: 1rem;
    margin: 0em 1rem;
    .previous-chapter, .previous-chapter:hover {
      flex: auto;
      background: white;
      font-size: 1rem;
      font-weight: 600;
      width: 2.5rem;
    } 
    .next-chapter, .next-chapter:hover {
      flex: auto;
      flex-direction: row-reverse;
      background: white;
      font-size: 1rem;
      font-weight: 600;
      width: 2.5rem;

      .atticus-button-text {
        margin-left: 0em;
        margin-right: .5em;
      }
    }
  }

  .pdf-chapter-navigation {
    display: flex;
    gap: 1rem;
    margin: 1rem 1rem 0em 1rem;
    .previous-chapter, .previous-chapter:hover {
      flex: auto;
      background: white;
      font-size: 1rem;
      font-weight: 600;
      width: 2.5rem;
    }

    .next-chapter, .next-chapter:hover{
      flex: auto;
      flex-direction: row-reverse;
      background: white;
      font-size: 1rem;
      font-weight: 600;
      width: 2.5rem;

      .atticus-button-text {
        margin-left: 0em;
        margin-right: .5em;
      }
    }
  }

  .pdf-page-navigation {
    display: flex;
    gap: 1rem;
    margin: 0rem 1rem;
    .previous-page, .previous-page:hover {
      flex: auto;
      background: white;
      font-size: 1rem;
      font-weight: 600;
      width: 2.5rem;
    }

    .next-page, .next-page:hover {
      flex: auto;
      flex-direction: row-reverse;
      background: white;
      font-size: 1rem;
      font-weight: 600;
      width: 2.5rem;

      .atticus-button-text {
        margin-left: 0em;
        margin-right: .5em;
      }
    }
  }
}

.previewer-exports {

  .export-buttons{
    display: flex;
    gap: 1rem;
    margin: 1rem 1rem 0em 1rem;
  }

  .export-pdf, .export-pdf:hover, .export-epub, .export-epub:hover {
    flex: auto;
    font-size: 1rem;
    font-weight: 600;
    width: 2.5rem;
  } 
}

/* Smart Quotes*/
.apply-quotes-heading {
  font-size: 1rem;
  font-weight: 600;
  color: #171D21;
}

.apply-quotes-text {
  font-size: 14px;
  color: #74777A;
}

.apply-quotes-text-warning {
  font-size: 14px;
  color: #171D21;
  font-weight: 600;
}

.apply-quotes-btn {
  width: 100%;
}

.quotes-pagination-btn, .collaboration-pagination-btn {
  height: fit-content !important;
  padding: 0;
}

.inconsistencies-warning-icon-wrap{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
}

.inconsistency-heading {
  font-weight: 600;
  font-size: 1rem;
}

.inconsistency-count {
  width: 24px;
  height: 20px;
  background-color: #BFEFE4;
  color: $primary-bg-mint;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
}

.quote-table {
  width: 100%;
  border-radius: 4px;
}
.quote-table .ant-table-thead .ant-table-cell {
  background-color: #F3F3F3 !important;
  font-size: 1rem;
  color: #171D21;
  font-weight: 600;
}

.inconsistency-table-wrapper {
  padding: 0.5em 1.5rem 1rem;
  overflow-y: auto;
  height: calc(100vh - 242px);
  .quote-mix{
    color: red;
  }
}

.smart-quote-inconsistency-wrapper{
  padding: 1rem 1.5rem;
}

/*Upload Book*/

.upload-book-modal-heading{
  font-weight: 600;
  font-size: 1.5rem;
}

.upload-book-form{

  .ant-form-item{
    margin-bottom: 1rem;
  }

  .upload-book-form-label{
    color: #171D21;
    font-size: 1rem;
    font-weight: 600;
  }

  .ant-form-item-label.ant-form-item-label-left{
    padding: 0;
  }

  .form-item-upload{
    margin-bottom: 0.5rem;
  }
  
  .upload-book-dnd-container{
    height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    .upload-book-dnd-text{
      font-size: 1rem;
      font-weight: 600;

      .upload-book-dnd-text-highlight{
        color: #3363B9;
       }
    }
  }
  
  .ant-upload.ant-upload-drag .ant-upload {
    padding: 0;
  }

  .book-upload-footer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    .book-upload-footer-text{
      font-size: 14px;
      color: #454A4D;
    }
  }

  label.ant-form-item-no-colon{
    font-weight: 600;
  }

  span.ant-select-arrow{
    color: #171D21;
  }
}
/* Trim Sizes styles */

.trim-sizes-btn-group {
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  text-align: center;

  .ant-radio-button-wrapper {
    width: 100%;
  }

  .trim-sizes-rd-btn.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background-color:$secondary-btn-blue-hover;
    border-color: $primary-bg-blue;
    border-radius: 4px;
    color: black;
  }
}
.trim-size-content-wrapper{
  padding-left: 1em;
}

.trim-sp-lbl{
  display: flex;
  align-items: center;
  gap: 4px;
}
.trim-sizes-kdp-icon{
  width: 8px;
  height: 8px;
  background-color: #FF9900;
  margin-right: .25em;
  margin-left: .25em;
}

.trim-sizes-ingram-icon{
  width: 8px;
  height: 8px;
  background-color: #41AADB;
  margin-right: .25em;
  margin-left: .25em;
}

.my-books-no-content-sub-text{
  color: $secondary-bg-grey; 
}

// toolbar styles

.toolbar-menu-item {
  padding: 5px !important;
  width: 40px;
  svg {
    width: 24px !important;
    height: 24px !important;
  }
}
.align-toolbar-divider {
  margin: 0px;
}

.at-dropdown-toolbar {
  display: flex;
  align-items: center;
}

.at-toolbar-dropdown {
  width: 158px;
  .slate-ToolbarButton {
    width: auto!important;
    justify-content: start !important;
  }
  .slate-ToolbarButton:hover {
    background-color: transparent;
  }

  .at-toolbar-menu-col {
    width: 31px;
  }
}

.at-tick-square{
  display: none;

}

.at-toolbar-menu {
  width: 40px;
}

/* Full Page Image */

.fullpage-img-layout {
  height: 100%;

  .fullpage-img-area {
    height: 95% !important;
  }
}

.invite-dropdown-button {
  color: white;
  min-width: 5em;
  border-right: 1px solid white;

  &:hover,
  &:active,
  &:focus{
    color: white;
    opacity: .8;
    border-right: 1px solid white;
  }
}

.invite-popover {
  width: 100%;
  max-width: 450px;
  
  .ant-popover-inner-content {
    padding: 0;
  }
  
  .invite-popover-content {
    padding: 1em;
    background-color: #F1F5FD;

    .ant-list-item-meta-title {
      margin-bottom: 0;
    }
    .ant-list-item-meta-avatar {
      margin-right: .5em;
    }
  }
  
  .at-invite-select {
    margin: 0;
    
    .ant-input-wrapper {
      height: 40px;
      border: 1px solid #EBEBEB;
      border-radius: 5px;
      display: flex;
      align-items: center;

      .ant-input {
        all: unset;
        height: 40px;
        flex: 1;
        padding: 0 1em;
      }

      .ant-input-group-addon {
        all: unset;
        border-left: 1px solid #EBEBEB;
        padding: .125em 1em;
      }
    }
  }
}

.notification-popover {
  width: 100%;
  max-width: 350px;
  z-index: 1000;

  .ant-popover-inner-content {
    padding: 0;
  }

  .ant-skeleton {
    padding: 1em;
  }
  
  .ant-list-empty-text {
    padding: 0;
  }

  .notification-item {
    flex-direction: column;
    align-items: stretch;
    padding: 1em 1em;

    &.read {
      background-color: whitesmoke;
    }

    .notification-item-meta {
      min-height: 40px;

      .ant-list-item-meta-title {
        font-size: $font-size-xs;
        font-weight: 400;
        b {
          font-weight: 800;
        }
      }
      .ant-list-item-meta-avatar {
        margin-right: .5em;
      }
    }

    .notification-item-date {
      margin-left: .125em!important;
      font-size: $font-size-xs;

      p {
        margin-left: .5em;
        color: $lighter-gray-color1;
      }
    }
  }
  .notification-clear-btn {
    color: $slick-bg-btn-color;
    font-size: $font-size-sm;
  }
}

.blockquote-modal-content {
  font-size: 1rem;

  .at-form-input {
    @extend .blockquote-modal-content;
  }
  .apply-bquotee-btn{
    @extend .blockquote-modal-content;
  }
  .blockqute-btn-div {
    margin-top: $space-md;
  }
}

.manage-collaborators {
  .collaborators-content {
    position: relative;
    z-index: 10;
  }
  .overlay {
    position: absolute;
    background-color: $primary-background;
    top: 0;
    left: 0;
    bottom: 0;
    width: 320px;
    z-index: 5;
  }
  .ant-collapse {
    width: 100%;
    .ant-collapse-header {
      align-items: center!important;
    }
  }
}

.blockquote-modal-content {
  font-size: 1rem;

  .at-form-input {
    @extend .blockquote-modal-content;
  }
  .apply-bquotee-btn{
    @extend .blockquote-modal-content;
  }
  .blockqute-btn-div {
    margin-top: $space-md;
  }
}

/* Comments*/

.slate-comment {
  background-color: rgba(249, 173, 21, 0.29);
}

.active-comment {
  background-color: rgba(249, 173, 21, 0.8) !important;

  &::before {
    content: "";
    background-color: rgb(243, 192, 192);
    border-top: 3px dotted rgb(10, 53, 245);
    height: 3px;
    width: 50%;

  }
}

.comments-menu {
  scale: 75%;
}

.setting-area.collaboration {
  border-left: none;
  margin-top: 1rem;
  padding-right: 12px;

  .setting-area-col {
    height: 181px;

    .collaboration-select {
      width: 100%;
    }
  }

  .collaboration-rhs-wrapper {
    .collaboration-settings-wrapper {
      .settings-header {
          padding: 12px;
          .user-icon-wrapper {
            text-align: center;
          }

        .comment-label-col,
        .edits-label-col {
          font-size: 1rem;
          font-weight: 600;
          padding-right: 0.5rem;
        }

        .count-wrapper {
          width: 21px;
          height: 20px;
          background-color: #BFEFE4;
          color: #00BE95;
          font-size: 14px;
          font-weight: 600;
          border-radius: 4px;
          text-align: center;
          margin-right: 1rem;
        }
      }

      .settings-options-row {
        padding: 0 0.75rem 0.75rem;
      }
    }
  }

}

.slate-BalloonToolbar {
  width: 200px !important;
  padding: 0px !important;
  height: 32px !important;
  max-height: 32px !important;
  align-items: center;
  display: flex;
  min-height: 40px;
  background: #FFFFFF;
  z-index: 500;
  border-radius: 4px;
  border: 1px solid #d9d9d9;

  .slate-ToolbarButton-active,
  .slate-ToolbarButton:hover {
    width: 200px !important;
    height: 2rem !important;
    color: rgb(68, 68, 68) !important;
  }

  .slate-ToolbarButton {
    margin-top: auto !important;
    padding: 12px !important;
    margin: 0px !important;
    width: 200px !important;
    height: 2rem !important;
    justify-content: left;
  }
}

.floating-toolbar-icon-wrapper {
  font-size: 14px;
  font-weight: 600;
  color: #171D21;
  white-space: nowrap;

  .icon {
    display: inline-block;
    position: relative;
    height: 22px;
    width: 1rem;

    .icon-svg-wrapper{
      position: absolute;
      top: 40%;
      height: 100%;
    }
  }

  .floating-toolbar-button-text {
    padding-left: 0.5rem;
    display: inline-block;
  }
}

.collaboration-dropdown-menu {
  .ant-dropdown-menu-item {
    margin: 0 4px;
    border-radius: 4px;
  }

  .ant-dropdown-menu-item-selected {
    background-color: #E5F9F4 !important;
    font-weight: 600;
    color: #00BE95;

    .ant-dropdown-menu-title-content {
      margin-left: 0.5rem;
    }
  }
}

.comments-popover {
  position: absolute;
  width: 315px;
  height: auto;
  border-radius: 4px;
  border: 2px solid #e5e5e6;
  padding: 1rem;
  background-color: white;
  z-index: 10;

  .user-info-row {
    align-items: center;
    padding-bottom: 0.75rem;

    .profile-picture-col {
      padding-right: 0.5rem;
    }

    .username-col {
      font-size: 14px;
      font-weight: 600;
    }
  }

  .text-area-row {
    padding-bottom: 0.75rem;
    .comment-popover-textarea{
      max-height: 300px !important;
    }
  }

  .comment-btn-wrapper {
    padding-right: 0.75rem;
  }
}

.comment-thread-wrapper, .rhs-thread-wrapper {
  padding: 0.75rem 0 1rem;

  .comment-thread {
    border-radius: 0.25rem;
    margin-bottom: 1rem;
    background-color: #FFFFFF;

    &.active {
      background-color: rgba(249, 173, 21, 0.3);
    }

    .comment-thread-top {
      height: 4px;
      border-radius: 4px 4px 0 0;
      background-color: #FFC234;
    }

    .thread-content-wrapper {
      padding: 0.5rem 12px 12px;

      .comment-item {
        padding-bottom: 1rem;

        &.reply {
          border-top: 1px solid #E8E8E9;
          padding: 1rem 0;
        }

        .avatar-col {
          padding-right: 4px;
          align-self: center;
        }

        .user-details-col {
          padding-right: 4px;
          font-size: 14px;

          .user-name {
            font-weight: 600;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .user-type-row {
            width: max-content;
            .user-type {
              color: #454A4D;
            }
          }
        }
        .comment-label-col {
          height: fit-content;
          .comment-label {
            background-color: rgba(249, 173, 21, 0.3);
            height: 22px;
            font-size: 12px;
            color: #454A4D;
            border-radius: 4px;
            padding: 0.25rem 0.5rem;
            align-items: center;
            width:fit-content;
            margin: auto;
          }
        }

        .resolve-comment-col .resolve-icon-wrapper {
          height: 18px;
          width: 18px;
          margin: auto;
          cursor: pointer;
        }

        .duration {
          font-size: 14px;
          color: #A2A5A6;
          text-align: center;
        }

        .comment-options {
          float: right;
        }

        .edit-comment-btns {
          padding-top: 12px;

          .save-btn {
            padding-right: 12px;
          }
        }

        .comment-text{
          white-space: pre-wrap;
          overflow-wrap: break-word;
          padding-top: 0.5rem;
        }
      }

      .text-area-container {
        position: relative;
        width: 100%;
      
        .reply-text-area {
          width: 100%;
          padding-right: 30px;
        }
        
        .submit-reply-btn {
          padding: 0;
          width: 20px;
          position: absolute;
          bottom: -0.25rem;
          right: 10px;
        }
      }
    }
  }
}

.track-change-thread-wrapper, .rhs-thread-wrapper {
  padding: 0.75rem 0 1rem;

  .track-change-thread {
    border-radius: 0.25rem;
    margin-bottom: 1rem;
    background-color: #FFFFFF;

    &.active {
      background-color: rgba(206, 224, 245);
    }

    .track-change-thread-top {
      height: 4px;
      border-radius: 4px 4px 0 0;
      background-color: #8CBCF4;
    }

    .thread-content-wrapper-tc {
      padding: 0.5rem 12px 12px;

      .track-change-item {
        padding-bottom: 1rem;

        &.reply {
          border-top: 1px solid #E8E8E9;
          padding: 1rem 0;
        }

        .avatar-col {
          padding-right: 4px;
          align-self: center;
        }

        .user-details-col {
          padding-right: 4px;
          font-size: 14px;

          .user-name {
            font-weight: 600;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .user-type {
            color: #454A4D;
          }
        }

        .track-change-label {
          background-color: #8CBCF4;
          height: 22px;
          font-size: 12px;
          color: #454A4D;
          border-radius: 4px;
          padding: 0.25rem 0.5rem;
          align-items: center;
          width:fit-content;
          margin: auto;
        }

        .track-change-options {
          float: right;
        }

        .duration {
          font-size: 14px;
          color: #A2A5A6;
          text-align: center;
        }

        .edit-track-change-btns {
          padding-top: 12px;

          .save-btn {
            padding-right: 12px;
          }
        }

        .track-change-text{
          white-space: pre-wrap;
          padding-top: 0.5rem;
          overflow-wrap: break-word;
          display: "inline-block"
        }

        .track-change-action-btns{
          padding-top: 0.75rem;
        }

        .approve-btn{
          color: $primary-bg-mint;
          font-weight: 600;
        }

        .decline-btn{
          color: $secondary-bg-red;
          font-weight: 600;
        }
      }

      .text-area-container {
        position: relative;
        width: 100%;
      
        .reply-text-area {
          width: 100%;
          padding-right: 30px;
        }
        
        .submit-reply-btn {
          padding: 0;
          width: 20px;
          position: absolute;
          bottom: -0.25rem;
          right: 10px;
        }
      }
    }
  }
}
.track-change-menu {
  scale: 75%;
}

